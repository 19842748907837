import React from 'react'
import PropTypes from 'prop-types'

const Video = ({title, vimeoId}) => (
	<div className="video">
		<iframe
			title={title}
			src={`https://player.vimeo.com/video/${vimeoId}`}
			width="500"
			height="281"
			frameBorder="0"
			webkitallowfullscreen
			mozallowfullscreen
			allowFullScreen
		/>
	</div>
)

Video.propTypes = {
	title: PropTypes.string,
	vimeoId: PropTypes.string.isRequired,
}

export default Video
