import React from 'react'
import {graphql} from 'gatsby'
import Slider from 'react-slick'

import 'slick-carousel/slick/slick.css'

import Layout from '../layout'
import SEO from '../components/seo'
import Image from '../components/contentful/image'
import Video from '../components/video'

const IndexPage = ({data}) => {
	const films = data.films.items.filter(({image}) => !!image)

	return (
		<Layout>
			<SEO {...data.metadata} />
			<Slider
				dots
				draggable
				infinite
				swipeToSlide
				arrows={false}
				customPaging={i => {
					const film = films[i]

					return (
						<button>
							<Image small {...film.image} />
							<p className="slick-cta">Watch Video</p>
							<p className="slick-title">{film.title}</p>
						</button>
					)
				}}
				slidesToShow={1}
			>
				{films.map(film => (
					<Video key={film.id} title={film.title} vimeoId={film.vimeoId} />
				))}
			</Slider>
		</Layout>
	)
}

export default IndexPage

export const query = graphql`
	{
		metadata: contentfulMetadata(title: {eq: "Homepage"}) {
			...ContentfulMetadataFragment
		}
		films: contentfulList(name: {eq: "Homepage Films"}) {
			items {
				... on ContentfulFilm {
					id
					title
					vimeoId
					image: thumbnailImage {
						...ContentfulImageFragment
					}
				}
			}
		}
	}
`
